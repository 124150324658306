* {
	font-family: Roboto, sans-serif;
}

body {
	font-size: 13px;
	margin: 0;
	font-family: 'Montserrat', '-apple-system,BlinkMacSystemFont', 'Segoe UI',
		'Roboto', 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
		'Segoe UI Emoji', 'Segoe UI Symbol';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

*::-webkit-scrollbar {
	width: 12px; /* width of the entire scrollbar */
}

*::-webkit-scrollbar-track {
	background: rgb(253, 253, 253, 0); /* color of the tracking area */
}

*::-webkit-scrollbar-thumb {
	background-color: #656565;
	border-radius: 20px;
	border: 3px solid white;
}

.MuiInputBase-input {
	padding-left: 15px !important;
}

.MuiInput-input {
	padding-left: 15px !important;
}
